import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers";

const API_URL = Global.url;

class ReportService {
  getReport(url) {
    const ruta = `${API_URL}/report/general-inventory?url=${url}`;
    return axios.post(ruta, {}, {
        headers: headerRequest(),
    }).catch((error) => {
        return error;
    });
  };
}

export default new ReportService();
